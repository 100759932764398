import { useRef } from 'react';

import useDebounce from './useDebounce';
import { useMountLayout } from './useMount';

export function useElementResize(onResize, debounceTime) {
  if (typeof onResize !== 'function') {
    throw new Error('onResize must be a function');
  }

  const elementRef = useRef(null);

  const debouncedOnResize = useDebounce(onResize, debounceTime);

  useMountLayout(() => {
    if (!elementRef.current) {
      return;
    }

    onResize(elementRef.current.getBoundingClientRect());

    const resizeObserver = new ResizeObserver(([{ contentRect }]) =>
      debouncedOnResize(contentRect),
    );

    resizeObserver.observe(elementRef.current);

    return () => resizeObserver.disconnect();
  });

  return { ref: elementRef };
}
