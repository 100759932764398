import moment from 'moment-timezone';
import PropTypes from 'prop-types';

/**
 * Provides an object of shared React props used for validating
 * a Deskpass space
 *
 * @return   {Object}  Props object
 */
const spaceProps = () => {
  return {
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    neighborhood: PropTypes.string,
    addressCity: PropTypes.string.isRequired,
  };
};

/*
 * Date or moment instance
 */
const dateType = PropTypes.oneOfType([
  PropTypes.instanceOf(Date),
  PropTypes.instanceOf(moment),
  PropTypes.string,
]);

/**
 * Provides an object of shared React props used for validating
 * an Hourly space
 *
 * @return   {Object}  Props object
 */
const roomProps = () => {
  return {
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    hourlyRate: PropTypes.number.isRequired,
    dayRate: PropTypes.number.isRequired,
    maxOccupancy: PropTypes.number.isRequired,
    space: PropTypes.shape(spaceProps()),
  };
};

/**
 * Provides an object of shared React props used for validating
 * an Office reservation
 *
 * @return {Object}  Props object
 */
const officeReservationProps = () => ({
  id: PropTypes.number,
  dateCategory: PropTypes.oneOf(['past', 'current', 'upcoming']),
  modelName: PropTypes.oneOf(['OfficeBooking']).isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  space: PropTypes.shape(spaceProps()),
  room: PropTypes.shape(roomProps()),
});

/**
 * Provides an object of shared React props used for validating
 * a Deskpass reservation
 *
 * @return   {Object}  Props object
 */
const deskpassReservationProps = () => {
  return {
    id: PropTypes.number,
    dateCategory: PropTypes.oneOf(['past', 'current', 'upcoming']),
    modelName: PropTypes.oneOf(['Booking']).isRequired,
    dateBooked: PropTypes.string.isRequired,
    dateCheckin: PropTypes.string,
    guests: PropTypes.array,
    guestCount: PropTypes.number,
    space: PropTypes.shape(spaceProps()),
  };
};

/**
 * Provides an object of shared React props used for validating
 * an Hourly reservation
 *
 * @return   {Object}  Props object
 */
const hourlyReservationProps = () => {
  return {
    id: PropTypes.number,
    dateCategory: PropTypes.oneOf(['past', 'current', 'upcoming']),
    modelName: PropTypes.oneOf(['HourlyBooking']).isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    space: PropTypes.shape(spaceProps()),
    room: PropTypes.shape(roomProps()),
    guests: PropTypes.array,
  };
};

/**
 * Provides an object of shared React props used for validating
 * a user
 *
 * @return   {Object}  Props object
 */
const userProps = () => {
  return {
    id: PropTypes.number,
    fullName: PropTypes.string,
    email: PropTypes.string,
    profileImageURL: PropTypes.string,
  };
};

const refType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(window.HTMLElement) }),
]);

const childrenType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node),
]);

const cssPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func])),
]);

const receiptPropType = {
  itemType: PropTypes.oneOf([
    'Office Reservation',
    'Meet Reservation',
    'Reservation Guest',
    'Desk Reservation',
    'Deskpass Subscription',
    'Other',
  ]).isRequired,
  chargeType: PropTypes.oneOf([
    'Card Charge',
    'Other',
    'Team Prepay Charge',
    'Team Card Charge',
    'Invoiced',
    'Free',
    'Subscription Credit',
  ]).isRequired,
  status: PropTypes.oneOf([
    'Complete',
    'Partial Refund',
    'Refund',
    'Invoiced',
    'Incomplete',
  ]).isRequired,
  amount: PropTypes.number,
  itemDate: PropTypes.string.isRequired,
  spaceName: PropTypes.string,
  roomName: PropTypes.string,
  amountRefunded: PropTypes.number,
};

/**
 * Check proptype is an instance of the specified component.
 * @param {React.Component} component
 */
function instanceOfComponentType(component) {
  return (props, propName, componentName) => {
    const propType = props[propName].type.displayName;
    const componentType = component.displayName;
    if (propType !== componentType) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Expected type of \`${componentType}\` and received \`${propType}\`. Validation failed.`,
      );
    }
  };
}

export {
  spaceProps,
  roomProps,
  deskpassReservationProps,
  hourlyReservationProps,
  officeReservationProps,
  userProps,
  dateType,
  refType,
  childrenType,
  cssPropType,
  receiptPropType,
  instanceOfComponentType,
};
