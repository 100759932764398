import styled, { css, keyframes } from 'styled-components';

const unblur = keyframes`
  from {
    filter: blur(10px);
  }
  to {
    filter: blur(0);
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.faint};
  filter: blur(10px);

  ${({ $unblur }) => $unblur && css`
    filter: blur(0);
  `}
`;


export const BlurredImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: ${unblur} 0.5s ease-in;
  transition: opacity 0.5s ease-in-out;
`;

export const ErrorOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.5;
  background-color: ${({ theme }) => theme.colors.lightGray};
`;

export const RetryButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-weight: bold;
`;
