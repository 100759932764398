import styled, { css } from 'styled-components/macro';

import mq from '@/styles/js/utils/mq';
import units from '@/styles/js/units';
import { rgba } from '@/styles/js/utils/colors';

import { ModalContent as StyledModalContent } from '@/components/modals/ModalContent/index.styles';

const modalTransitions = css`
  transition: 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition-property: transform, right, opacity;
  will-change: transform, right, opacity;
`;

const modalShadow = css`
  box-shadow: 0 0 2rem ${rgba('black', 0.12)};
`;

const transparentDark = ({ theme }) => rgba(theme.colors.text, 0.2);
const transparentWhite = ({ theme }) => rgba(theme.colors.white, 0.9);

export const Dialog = styled.div`
  ${modalTransitions}
  ${modalShadow}

  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  ${mq({ until: 'medium' })} {
    height: 100vh;
  }

  &:focus {
    outline: none;
  }
`;

export const Content = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  ${mq({ until: 'smallMedium' })} {
    padding-bottom: 8rem;
  }
`;

export const Backdrop = styled.div`
  ${mq('medium')} {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 200ms;
    will-change: opacity;
    z-index: ${units.zIndex.modal};
    background-color: ${transparentDark};
  }

  ${(props) => props.hide && css`
    ${mq('medium')} {
      background-color: transparent;
    }
  `}

  &.is-transition-entering,
  &.is-transition-entered {
    opacity: 1;
  }
`;

// ---------------------------------------------------------------
// Modifier: Type / Side
// ---------------------------------------------------------------
const typeSideStyles = css`
  max-height: 100vh;
  max-width: 100vw;
  right: 0;
  transform: translateY(0) translateX(100vw);

  ${Dialog} {
    ${mq('medium')} {
      width: 50vw;
    }

    ${mq('xlarge')} {
      width: 33vw;
    }
  }

  &.is-transition-entered {
    ${mq('medium')} {
      transition-duration: 100ms;
      transform: translateY(0) translateX(0);
    }
  }

  &.is-transition-exiting {
    ${mq('medium')} {
      transform: translateY(0) translateX(100vw);
    }
  }

  ${StyledModalContent} {
    // Fix modal content height and scroll on Firefox -- other browsers work.
    max-height: 100vh;
    height: 100vh;
  }
`;

// ---------------------------------------------------------------
// Modifier: Type / Center
// ---------------------------------------------------------------
const typeCenterStyles = css`
  border-radius: 0.6rem;
  overflow-y: scroll;
  max-height: 100%;

  ${mq('medium')} {
    max-height: calc(100% - 3rem);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
  }

  ${Dialog} {
    ${mq('medium')} {
      max-width: 56rem;
      min-height: 66rem;
      border-radius: 0.6rem;
      box-shadow: 0 0 4rem rgba(black, 0.12);
      opacity: 0;
      margin: auto 0;
    }
  }

  // Transitions

  &.is-transition-entering,
  &.is-transition-entered {
    ${Dialog} {
      transform: translateY(0) translateX(0);
      opacity: 1;

      ${mq('medium')} {
        transition-duration: 300ms;
      }
    }
  }

  ${(props) => !!props.wide && css`
    ${Dialog} {
      ${mq('medium')} {
        max-width: 60vw;
      }
    }
  `}
`;

// ---------------------------------------------------------------
// Modifier: Type / Full
// ---------------------------------------------------------------
const typeFullStyles = css`
  height: 100vh;

  ${mq('medium')} {
    display: flex;
    justify-content: center;
  }

  ${Dialog} {
    ${mq('medium')} {
      opacity: 0;
      transition-duration: 300ms;
      margin: auto 0;
      box-shadow: none;
      min-width: 100vw;
    }
  }

  // Transitions

  &.is-transition-entering,
  &.is-transition-entered {
    transform: translateY(0) translateX(0);

    ${Dialog} {
      transform: translateY(0) translateX(0);
      opacity: 1;

      ${mq('medium')} {
        transition-duration: 300ms;
      }
    }
  }

  ${StyledModalContent} {
    // Fix modal content height and scroll on Firefox -- other browsers work.
    max-height: 100vh;
    height: 100vh;
  }
`;

export const Modal = styled.div`
  ${modalTransitions}
  top: env(safe-area-inset-top);
  bottom: env(safe-area-inset-bottom);
  z-index: ${units.zIndex.modal};
  position: fixed;
  height: 100vh;
  overflow: hidden;
  display: inline-table;

  &:focus {
    outline: none;
  }

  ${mq({ until: 'medium' })} {
    display: block;
    overflow: scroll;
  }

  ${mq({ until: 'medium' })} {
    ${modalShadow}
    width: 100%;
    transform: translateX(0) translateY(100vh);
  }

  &.is-transition-entering,
  &.is-transition-entered {
    ${mq({ until: 'medium' })} {
      transform: translateX(0) translateY(0);
    }
  }

  &.is-transition-exited {
    ${mq({ until: 'medium' })} {
      transform: translateX(0) translateY(100vh);
    }
  }

  // Types

  ${(props) => props.type === 'side' && typeSideStyles}
  ${(props) => props.type === 'center' && typeCenterStyles}
  ${(props) => props.type === 'full' && typeFullStyles}

  // Modifiers

  ${(props) => props.fillViewHeight && css`
    &&& {
      max-height: 80vh;
      height: fit-content;
    }
  `}

  ${Dialog} {
    ${(props) => props.bgWarning && css`
      background-color: ${props.theme.colors.red};
    `}

    ${(props) => props.bgTransparent && css`
      background-color: transparent;
    `}

    ${(props) => props.bgTransparentDark && css`
      background-color: ${transparentDark};
    `}

    ${(props) => props.bgTransparentWhite && css`
      background-color: ${transparentWhite};
    `}

    ${(props) => props.fitToContent && css`
      ${mq('medium')} {
        min-height: 0;
      }
    `}
  }
`;
