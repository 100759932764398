import haversine from 'haversine';

// Map the fields that every marker needs
const _mapBaseMarkerFields = (it) => ({
  id: it.spaceId ? it.spaceId : it.id,
  slug: it.spaceSlug ? it.spaceSlug : it.slug,
  name: it.spaceName ? it.spaceName : it.name,
  lat: it.lat,
  lng: it.lng,
  open: it.open,
  isRoom: !!it.spaceId,
  isPrivate: it.isPrivate,
  currentlyUnavailable: it.currentlyUnavailable,
  featured: it.featured,
  bookingCost: it.bookingCost,
  image: it.image,
  currencySymbol: it.currencySymbol,
  roomsOnly: it.roomsOnly,
});

// Map the fields neeed by the room list on the Hourly mode marker
const _mapMarkerRoomListFields = (it) => ({
  id: it.id,
  slug: it.slug,
  maxOccupancy: it.maxOccupancy,
  hourlyRate: it.hourlyRate,
  dayRate: it.isOffice ? it.officeDayRate : it.dayRate,
  weekRate: it.weekRate,
  monthRate: it.monthRate,
  currencySymbol: it.currencySymbol,
  spaceSlug: it.spaceSlug,
  isOffice: it.isOffice,
  roomsOnly: it.roomsOnly,
});

export const kmDistanceBetween = (coords1, coords2) => {
  return (
    haversine(
      { latitude: coords1.lat, longitude: coords1.lng },
      { latitude: coords2.lat, longitude: coords2.lng },
      { unit: 'meter' },
    ) / 1000
  );
};

/*
 * Expects list of spaces or list of rooms.
 *
 * Returns parsed list of markers for spaces or spaces with rooms (For hourly mode).
 */
export const parseMarkersFromSpacesOrRooms = ({ spaces, rooms }) => {
  // Map only the important bits of the maker for Deskpss mode
  if (!!spaces && spaces.length) {
    return spaces.map(_mapBaseMarkerFields);
  }
  // Map only the important bits of the maker for Hourly mode
  // Meaning each marker have the same fields it has on deskpass
  // mode, plus a list of rooms
  if (!!rooms && rooms.length) {
    return Object.values(
      rooms.reduce((acc, room) => {
        // On new spaceId, create a new entry on the dictionary
        if (!acc[room.spaceId]) {
          return {
            ...acc,
            [room.spaceId]: {
              ..._mapBaseMarkerFields(room),
              rooms: [_mapMarkerRoomListFields(room)],
            },
          };
        }
        // When the spaceId key exists, just add a new room to the list
        return {
          ...acc,
          [room.spaceId]: {
            ...acc[room.spaceId],
            rooms: acc[room.spaceId].rooms.concat(
              _mapMarkerRoomListFields(room),
            ),
          },
        };
      }, {}),
    );
  }

  return [];
};

export const closestCityByCoords = (coords, cities) => {
  if (!coords || !cities) return null;

  return cities.reduce((closestCity, nextCity) => {
    const distanceToClosest = kmDistanceBetween(coords, closestCity);
    const distanceToNext = kmDistanceBetween(coords, nextCity);

    return distanceToClosest < distanceToNext ? closestCity : nextCity;
  }, cities[0]);
};
