import PropTypes from 'prop-types';
import { memo, useRef, useState } from 'react';

import useEvent from '@/hooks/useEvent';
import { useMountLayout } from '@/hooks/useMount';
import useWindowResize from '@/hooks/useWindowResize';

import { getCloudflareImageURL } from '@/lib/images';

import * as S from './index.styles';

export const Image = memo(
  ({
    src,
    cloudflareOptions = {},
    upscalingMultiplier = 1.5,
    blurredImageFractionSize = 7,
    loadingType = 'lazy',
    blurredLoadingType = 'eager',
    renderErrorFallback,
    className,
    style,
    height,
    width,
    onLoad,
    onError,
    ...props
  }) => {
    const containerRef = useRef(null);

    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [imgSize, setImgSize] = useState();

    const handleLoad = useEvent(() => {
      setLoaded(true);
      onLoad?.();
    });

    const handleError = useEvent(() => {
      setError(true);
      onError?.();
    });

    const updateDimenions = useEvent(() => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();

        const newImgSize = {
          width: width * upscalingMultiplier,
          height: height * upscalingMultiplier,
        };

        setImgSize(newImgSize);
      }
    });

    useMountLayout(() => {
      setMounted(true);
      updateDimenions();
    });

    useWindowResize({ onResize: updateDimenions });

    const imageURL = getCloudflareImageURL(src, {
      quality: 100,
      fit: 'crop',
      ...imgSize,
      ...cloudflareOptions,
    });

    const containerStyle = {
      height: `${height}px`,
      width: `${width}px`,
      ...style,
    };

    let imgStyles = {
      opacity: 0,
    };

    const retry = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setError(false);
    };

    if (loaded) {
      imgStyles.opacity = 1;
    }

    return (
      <S.ImageWrapper
        $unblur={loaded || error}
        style={containerStyle}
        className={className}
        ref={containerRef}
      >
        {mounted && (
          <>
            {!error && (
              <>
                <S.BlurredImage
                  alt={props.alt ?? 'Blurred Image'}
                  loading={blurredLoadingType}
                  src={getCloudflareImageURL(src, {
                    quality: 50,
                    width: imgSize.width / blurredImageFractionSize,
                    height: imgSize.height / blurredImageFractionSize,
                  })}
                />

                <S.Image
                  loading={loadingType}
                  src={imageURL}
                  onLoad={handleLoad}
                  onError={handleError}
                  style={imgStyles}
                  {...props}
                />
              </>
            )}

            {error && (
              <>
                {typeof renderErrorFallback === 'function' ? (
                  renderErrorFallback({
                    reload: retry,
                  })
                ) : (
                  <S.ErrorOverlay>
                    Could not load image.
                    <br />
                    <span>
                      Click
                      <S.RetryButton onClick={retry}>here</S.RetryButton>
                      to retry.
                    </span>
                  </S.ErrorOverlay>
                )}
              </>
            )}
          </>
        )}
      </S.ImageWrapper>
    );
  },
);

export const imagePropTypes = {
  src: PropTypes.string.isRequired,
  cloudflareOptions: PropTypes.object,
  upscalingMultiplier: PropTypes.number,
  blurredImageFractionSize: PropTypes.number,
  loadingType: PropTypes.string,
  blurredLoadingType: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  renderErrorFallback: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  onLoad: PropTypes.func,
  onError: PropTypes.func,
  alt: PropTypes.string,
};
