import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useState } from 'react';

import { Image } from '@/components/graphics/Image';
import Badge from '@/components/labels/Badge';
import BadgeList from '@/components/labels/BadgeList';
import SquareBadge from '@/components/rooms-or-spaces/SquareBadge';

import { useUserContext } from '@/context/User';

import { useElementResize } from '@/hooks/useElementResize';
import useEvent from '@/hooks/useEvent';

import * as S from './RoomOrSpaceImage.styles';

const RoomOrSpaceImage = forwardRef(
  (
    {
      featured = false,
      highlighted = false,
      hq = false,
      isSafeSpace = false,
      bookInstantly = false,
      showLogo = false,
      renderOverImage = {},
      rightBadgePadding = false,
      size,
      ...imageProps
    },
    ref,
  ) => {
    useImperativeHandle(ref, () => wrapperRef.current);

    const [dimensions, setDimensions] = useState({});

    const setSizeModifierFromRect = useEvent((rect) => {
      let { width } = rect;
      const height = width * 0.6;
      setDimensions({ height, width });
    });

    const { ref: wrapperRef } = useElementResize(setSizeModifierFromRect, 200);

    const { teamUser } = useUserContext();

    const shouldRenderBottomRight =
      (isSafeSpace || bookInstantly) && !renderOverImage.bottomRight;

    const shouldRenderTopLeft =
      (teamUser || showLogo) && !renderOverImage.topLeft;

    return (
      <S.RoomOrSpaceImage ref={wrapperRef}>
        <Image {...imageProps} {...dimensions} />

        {shouldRenderBottomRight && (
          <S.RoomOrSpaceImagePlacement
            placement="bottomRight"
            rightBadgePadding={rightBadgePadding}
          >
            <BadgeList>
              {bookInstantly && (
                <Badge
                  color="white"
                  icon="bolt-17"
                  iconProps={{ color: '#00b0b1' }}
                  {...(size && { size })}
                >
                  <strong>Book Instantly</strong>
                </Badge>
              )}
            </BadgeList>
          </S.RoomOrSpaceImagePlacement>
        )}

        {shouldRenderTopLeft && (
          <S.RoomOrSpaceImagePlacement placement="topLeft">
            <SquareBadge
              hq={hq}
              showLogo={showLogo}
              highlighted={highlighted}
              featured={featured}
              {...(size && { size })}
            />
          </S.RoomOrSpaceImagePlacement>
        )}

        {Object.keys(renderOverImage).map((placement) => {
          const renderOnPlacement = renderOverImage[placement];
          if (typeof renderOnPlacement === 'function') {
            return (
              <S.RoomOrSpaceImagePlacement
                key={placement}
                placement={placement}
              >
                {renderOnPlacement({ placement, sizeModifier: size })}
              </S.RoomOrSpaceImagePlacement>
            );
          }

          return null;
        })}
      </S.RoomOrSpaceImage>
    );
  },
);

RoomOrSpaceImage.propTypes = {
  renderOverImage: PropTypes.shape({
    topRight: PropTypes.func,
    topLeft: PropTypes.func,
    bottomLeft: PropTypes.func,
    bottomRight: PropTypes.func,
  }),
  bookInstantly: PropTypes.bool,
  featured: PropTypes.bool,
  highlighted: PropTypes.bool,
  showLogo: PropTypes.bool,
  isSafeSpace: PropTypes.bool,
  hq: PropTypes.bool,
  rightBadgePadding: PropTypes.bool,
  ...Image.propTypes,
};

export default RoomOrSpaceImage;
