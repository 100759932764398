// Avatar.js
import styled, { css } from 'styled-components';

const size = (props) => {
  if (props.mini) {
    return '2.4rem';
  } else if (props.small) {
    return '12rem';
  } else {
    return '40rem';
  }
};

export const Avatar = styled.div`
  width: ${size};
  height: ${size};
`;

export const AvatarInner = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
`;

export const ErrorOverlay = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  ${props => props.mini && css`
    & > span > svg {
      padding: 3px;
    }
  `}
`;
