import PropTypes from 'prop-types';

import FluidSvg from '@/components/graphics/FluidSvg';
import Icon from '@/components/graphics/Icon';
import { Image } from '@/components/graphics/Image';
import SvgUse from '@/components/graphics/SvgUse';

import * as S from './Avatar.styles';

/**
 * A image representing a user.
 */
const Avatar = ({ imageUrl, ...otherProps }) => {
  return (
    <S.Avatar {...otherProps}>
      <S.AvatarInner>
        {imageUrl && (
          <Image
            src={imageUrl}
            alt="User Avatar image"
            renderErrorFallback={({ reload }) => (
              <S.ErrorOverlay onClick={reload}>
                {otherProps.mini ? (
                  <Icon type="reload-24" />
                ) : (
                  <p>
                    <Icon type="reload-24" /> Reload
                  </p>
                )}
              </S.ErrorOverlay>
            )}
          />
        )}
        {!imageUrl && (
          <FluidSvg percent={100} absolute>
            <SvgUse id="default-user" category="full" />
          </FluidSvg>
        )}
      </S.AvatarInner>
    </S.Avatar>
  );
};

Avatar.propTypes = {
  mini: PropTypes.bool,
  small: PropTypes.bool,
  imageUrl: PropTypes.string,
};

export default Avatar;
